var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"mt-10",staticStyle:{"background":"white","min-height":"700px"}},[_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"ma-5 d-flex",staticStyle:{"background":"#B6EDFF","border-radius":"30px"},attrs:{"cols":"4"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"mr-5 blue--text"},[_vm._v(_vm._s(_vm.$t('messages.managerIdCustomer')))]),_vm._v(" "+_vm._s(_vm.customerDetail.code)+" ")]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"mr-5 blue--text"},[_vm._v(_vm._s(_vm.$t('messages.factoryName')))]),_vm._v(" "+_vm._s(_vm.name)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',[_vm._v(_vm._s(_vm.$t('messages.applicablePeriod')))])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"return-value":_vm.startAt,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.startAt=$event},"update:return-value":function($event){_vm.startAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"max":_vm.getMaxDate,"locale":"ja","no-title":"","scrollable":""},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuStart = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuStart.save(_vm.startAt)}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_vm._v(" ~ ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"return-value":_vm.endAt,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.endAt=$event},"update:return-value":function($event){_vm.endAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"locale":"ja","no-title":"","scrollable":"","min":_vm.getMinDate},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuEnd = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuEnd.save(_vm.endAt)}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',[_vm._v(_vm._s(_vm.$t('messages.applicableMethod')))])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.methodsList,"item-text":"text","item-value":"value"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticStyle:{"border":"1px solid #5CA6D2","border-radius":"10px"},attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.type === 0 ? _vm.$t('combobox.manage_by_factory') : _vm.$t('combobox.manage_by_job'))+" ")])],1),(_vm.type === 1)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"footer-props":{
                itemsPerPageOptions: [5, 10, 15, 30, 50],
                showFirstLastPage: false,
                'items-per-page-text': _vm.$t('table.messages.items_per_page'),
              }},scopedSlots:_vm._u([{key:"item.isUsed",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":""},model:{value:(item.isUsed),callback:function ($$v) {_vm.$set(item, "isUsed", $$v)},expression:"item.isUsed"}})]}},{key:"item.index",fn:function(ref){
              var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.manageByShift",fn:function(ref){
              var item = ref.item;
return [_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{staticClass:"d-flex justify-center",attrs:{"hide-details":""},model:{value:(item.manageByShift),callback:function ($$v) {_vm.$set(item, "manageByShift", $$v)},expression:"item.manageByShift"}})],1)]}},{key:"item.shift",fn:function(ref){
              var item = ref.item;
return [(item.manageByShift === true)?_vm._l((item.shifts),function(shift,index){return _c('v-row',{key:index,staticClass:"d-flex mb-1 mt-1",staticStyle:{"background":"rgb(182, 237, 255)","border-radius":"20px"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v(_vm._s(shift.shiftStartAt + ' ~ ' + shift.shiftEndAt))])])],1)}):_vm._e()]}},{key:"item.hourlyRevenue",fn:function(ref){
              var item = ref.item;
return [(item.manageByShift === true)?_vm._l((item.shifts),function(shift,index){return _c('v-row',{key:index,staticClass:"mb-2 mt-n2"},[_c('v-text-field',{attrs:{"type":"number","rules":_vm.hourlyRules,"min":"0","suffix":_vm.$t('suffix.money'),"hide-details":"auto"},model:{value:(shift.hourlyRevenue),callback:function ($$v) {_vm.$set(shift, "hourlyRevenue", $$v)},expression:"shift.hourlyRevenue"}})],1)}):[_c('v-text-field',{attrs:{"type":"number","rules":_vm.hourlyRules,"min":"0","suffix":_vm.$t('suffix.money'),"hide-details":"auto"},model:{value:(item.hourlyRevenue),callback:function ($$v) {_vm.$set(item, "hourlyRevenue", $$v)},expression:"item.hourlyRevenue"}})]]}},{key:"item.note",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"auto"},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}},{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.messages.page'))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"footer.prepend",fn:function(){return undefined},proxy:true}],null,false,883611614)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('table.messages.no_data'))+" ")])],2)],1):_c('v-row',{staticClass:"d-flex justify-center align-start"},[_c('v-col',{staticClass:"d-flex align-start",attrs:{"cols":"7"}},[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.$t('messages.contract')))]),_c('v-text-field',{staticClass:"mt-n4 ml-5 shrink",staticStyle:{"width":"100px"},attrs:{"solo":"","type":"number","min":"0","rules":_vm.hourlyRules,"suffix":_vm.$t('suffix.money')},model:{value:(_vm.hourlyRevenue),callback:function ($$v) {_vm.hourlyRevenue=$$v},expression:"hourlyRevenue"}})],1),_c('v-col',{staticClass:"d-flex justify-center ml-n15 mr-15",attrs:{"cols":"12"}},[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.$t('messages.note')))]),_c('v-text-field',{staticClass:"mt-n4 ml-5 shrink",staticStyle:{"width":"40%"},attrs:{"solo":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","large":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")]),_c('v-btn',{attrs:{"color":"dark darken-1","large":""},on:{"click":_vm.redirectDetail}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")])],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }